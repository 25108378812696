import { Route,Redirect } from "react-router-dom";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'


 export const RouteInterceptor = ({ children, isAuthenticated, ...rest }) => {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          isAuthenticated ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: AUTH_PREFIX_PATH,
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  }

  export const AuthRoute = ({ children, isAuthenticated, ...rest }) => {
    return (
      <Route
        {...rest}
        render={({ location }) =>
          !isAuthenticated ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: APP_PREFIX_PATH,
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  }